import React, { Component } from "react"
import { Col, Row } from "reactstrap"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"

import {
  BlockImageTextHalf,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/moravske-drevostavby/logo.png"
import Header from "../../../images/reference/moravske-drevostavby/header-marketing.png"
import Sideimg from "../../../images/reference/moravske-drevostavby/screen-1.png"
import Ales from "../../../images/reference/moravske-drevostavby/reaction-devel.png"

class Drevostavby extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      background: `#392127`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na nový web pro MORAVSKÉ DŘEVOSTAVBY | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//moravske-drevostavby.cz"
          heading="Reference na nový web pro MORAVSKÉ DŘEVOSTAVBY"
          odstavec="2014"
        >
          <Row>
            <Col md={6}>
              <p className="mb-4 w-100 pt-0 text-left">
                Společnost MORAVSKÉ DŘEVOSTAVBY se zabývá výstavbou energeticky
                úsporných a nízkoenergetických typových dřevostaveb za použití
                Systému DNK. V jejich práci si berou za vzor moravskou poctivost
                a pohostinnost.
              </p>
            </Col>

            <Col md={6} className="d-none d-lg-block">
              <img src={Header} alt="" className="img-fluid" loading="lazy" />
            </Col>
          </Row>
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p>
                Vytvořit webové stránky pro nově založenou společnost zabývající
                se výstavbou dřevostaveb.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>krátký průzkum trhu a návrh wireframů</li>
                <li>vytvoření vlastní grafiky</li>
                <li>zpracování do HTML kódu (neresponzivní)</li>
                <li>nasazení na redakní systém Drupal</li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Vyjádření zadavatele</TitleH2>
              <p>
                <em>
                  Jsem asi poněkud skeptický k doporučením na různé služby
                  přátel svých obchodních partnerů, ale musím přiznat, že pan
                  Kudlík podpořil svou zajímavou nabídku seriózním a odborným
                  přístupem k mému zadání. Slovo dalo slovo a pod rukama jeho
                  týmu se začal rodit náš web. Zprvu to znamenalo jen dodávání
                  dalších a dalších textů a obrázků, ale pak se se objevila
                  první grafika a web nám doslova vyrostl pod rukama.
                </em>
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <Medailon
            wide
            image={Ales}
            alt=""
            text="Spolupráci s panem Kudlíkem a jeho společností igloonet s.r.o.
            hodnotím kladně, protože dodrželi zadání projektu, rozpočet i časový harmonogram."
            name="Ing. Aleš Tajbr, Ph.D."
            position="ředitel"
          />
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default Drevostavby
